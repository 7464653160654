import {format, differenceInSeconds} from 'date-fns';
import {de} from 'date-fns/locale/de'

export const TAG_NAME = 'ft9-benefit-countdown-v1';
export const EXPIRATION_DATE_ATTRIBUTE = 'expiration-date';
export const UPDATE_INTERVAL_ATTRIBUTE = 'update-interval';
export const FORMAT_ATTRIBUTE = 'format';

const ONE_MINUTE = 60;
const ONE_HOUR = ONE_MINUTE * 60;
const ONE_DAY = ONE_HOUR * 24;
const TWO_WEEKS = ONE_DAY * 7 * 2;

type ScarcityType = 'expired' | 'upTo24Hours' | 'tomorrow' | 'upToTwoWeeks' | 'moreThanTwoWeeks';

export class CountdownComponent extends HTMLElement {
    private root: ShadowRoot;
    private expirationDate: Date = new Date(0);

    private variant?: ScarcityType;

    private slotMap: Map<ScarcityType, HTMLSlotElement> = new Map();

    private intervalId?: ReturnType<typeof setInterval>;

    constructor() {
        super();
        this.root = this.attachShadow({mode: 'open'});
    }

    connectedCallback() {
        let expirationDate = this.getAttribute(EXPIRATION_DATE_ATTRIBUTE);
        if (!expirationDate) {
            return;
        }
        this.expirationDate = new Date(expirationDate);

        this.render();

        this.initCountdown();

        this.updateCountdownOnInterval()
    }

    private render() {
        this.root.innerHTML = `
          <slot name="expired" style="display: none"></slot>
          <slot name="upTo24Hours" style="display: none"></slot>
          <slot name="tomorrow" style="display: none"></slot>
          <slot name="upToTwoWeeks" style="display: none"></slot>
          <slot name="moreThanTwoWeeks" style="display: none"></slot>`;

        this.slotMap.set('expired', this.root.querySelector('slot[name="expired"]') as HTMLSlotElement);
        this.slotMap.set('upTo24Hours', this.root.querySelector('slot[name="upTo24Hours"]') as HTMLSlotElement);
        this.slotMap.set('tomorrow', this.root.querySelector('slot[name="tomorrow"]') as HTMLSlotElement);
        this.slotMap.set('upToTwoWeeks', this.root.querySelector('slot[name="upToTwoWeeks"]') as HTMLSlotElement);
        this.slotMap.set('moreThanTwoWeeks', this.root.querySelector('slot[name="moreThanTwoWeeks"]') as HTMLSlotElement);
    }

    private initCountdown() {
        /*                                                                                                                             */
        /*                                                                                                                                                          */
        /*                                                                                                                                              */

        let slotCounter = 0;
        let isInitialized = false; /*                                                                                   */

        const onSlotChange = () => {
            slotCounter++;
            if (slotCounter === this.slotMap.size - 1 && !isInitialized) {
                this.updateCountdown();
                this.slotMap.forEach((slot) => {
                    slot.removeEventListener('slotchange', onSlotChange);
                })
                isInitialized = true;
            }
        };

        this.slotMap.forEach((slot) => {
            slot.addEventListener('slotchange', onSlotChange)
        })
    }

    private updateCountdownOnInterval() {
        let updateIntervalInMilliseconds = this.getAttribute(UPDATE_INTERVAL_ATTRIBUTE);
        if (updateIntervalInMilliseconds) {
            this.intervalId = setInterval(() => {
                this.updateCountdown()
            }, parseInt(updateIntervalInMilliseconds));
        }
    }

    private updateCountdown() {
        const diff = differenceInSeconds(this.expirationDate, new Date());
        this.updateVariant(diff);
        this.displayVariant(diff);
    }

    private updateVariant(diff: number) {
        if (diff <= 0) {
            this.variant = 'expired';
            if (this.intervalId) {
                clearInterval(this.intervalId);
            }
        } else if (diff <= ONE_DAY) {
            this.variant = 'upTo24Hours';
        } else if (this.isTomorrow(this.expirationDate)) {
            this.variant = 'tomorrow';
        } else if (diff <= TWO_WEEKS) {
            this.variant = 'upToTwoWeeks';
        } else {
            this.variant = 'moreThanTwoWeeks';
        }
    }

    private displayVariant(timeDiff: number) {
        this.slotMap.forEach((slot) => {
            slot.style.display = slot.name === this.variant ? '' : 'none';
        });
        const assignedElement = this.slotMap.get(this.variant!)!.assignedElements()[0];

        if (!assignedElement) {
            return
        }

        Array.from(assignedElement.children).forEach((child) => {
            if (child instanceof HTMLElement && child.hasAttribute(FORMAT_ATTRIBUTE)) {
                const formatAttributeValue = child.getAttribute(FORMAT_ATTRIBUTE)!;

                if (formatAttributeValue.includes("timeDiff")) {
                    child.textContent = this.getFormattedCountdown(timeDiff);
                } else {
                    child.textContent = format(this.expirationDate, child.getAttribute(FORMAT_ATTRIBUTE)!, {locale: de});
                }
            }
        });
    }

    private getFormattedCountdown(timeDiff: number) {
        const hours = Math.floor((timeDiff % ONE_DAY) / ONE_HOUR);
        const minutes = Math.floor((timeDiff % ONE_HOUR) / ONE_MINUTE);
        const seconds = Math.floor(timeDiff % ONE_MINUTE);

        const hoursString = hours > 0 ? ` ${hours}h` : "";
        const minutesString = minutes > 0 ? ` ${minutes}m` : "";
        const secondsString = seconds > 0 ? ` ${seconds}s` : "";
        return `${hoursString}${minutesString}${secondsString}`.trim();
    }

    private isTomorrow(date: Date) {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1);

        return date.toDateString() === tomorrow.toDateString();
    }
}

customElements.define(TAG_NAME, CountdownComponent);